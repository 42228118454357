<template>
  <div class="tw-relative tw-flex tw-flex-col tw-space-y-[60px]">
    <section class="tw-relative tw-z-0 tw-text-white">
      <CommonHeader is-light />
      <div class="tw-home-za-bg tw-max-w-[1920px] tw-mx-auto">
        <section class="tw-container tw-mx-auto">
          <div
            class="before:tw-absolute before:tw-content-[''] before:tw-bg-[#020106] before:tw-top-0 before:tw-left-0 before:tw-h-[100%] before:tw-w-full before:-tw-z-[2]"
          >
            <section class="tw-relative tw-flex tw-flex-row tw-flex-wrap tw-py-8 lg:tw-py-14">
              <div class="tw-basis-full lg:tw-basis-6/12 tw-flex tw-flex-col tw-justify-between tw-text-center md:tw-text-left">
                <div>
                  <h1 class="tw-block !tw-leading-[1.32] tw-text-3xl sm:tw-text-4xl md:tw-text-5xl lg:tw-text-[52px] tw-font-semibold"
                    >A New Era of Digital Finance Arrives in South Africa</h1
                  >
                  <h2
                    class="!tw-leading-[1.58] tw-text-sm sm:tw-text-base md:tw-text-xl lg:tw-text-2xl tw-mt-5 md:tw-mt-6 lg:tw-mt-[30px] !tw-font-normal"
                    >Legend Trading delivers secure, region-focused, innovative digital finance solutions</h2
                  >
                  <div
                    class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-center lg:tw-justify-start tw-items-center tw-w-full tw-mt-20 sm:tw-mt-16 md:tw-mt-10 lg:tw-mt-[50px] tw-gap-7"
                  >
                    <UIButton to="/kyc" theme="secondary-4" class="tw-btn-lg tw-uppercase tw-rounded-full">
                      {{ $t('trade-with-us') }}
                    </UIButton>
                    <NuxtLinkLocale
                      to="/partner"
                      class="tw-text-white xl:tw-text-primary tw-text-lg md:tw-text-xl tw-font-semibold tw-underline tw-underline-offset-4"
                    >
                      <div>
                        {{ $t('or-become-a-partner') }}
                      </div>
                    </NuxtLinkLocale>
                  </div>
                </div>
                <div
                  class="tw-flex tw-justify-center md:tw-justify-start tw-items-center tw-gap-3 md:tw-gap-5 tw-text-sm lg:tw-text-xl tw-mt-24 xl:tw-mt-[200px] !tw-font-normal"
                >
                  <SvgoHomeFlagSouthAfrica class="tw-w-8 tw-h-5 lg:tw-w-14 lg:tw-h-10" />
                  Now Also Available in South Africa
                </div>
              </div>
              <div class="tw-hidden xl:tw-flex xl:tw-basis-6/12 tw-relative">
                <SvgoHomeZaIcon5 class="tw-absolute -tw-top-16 tw-left-40 tw-w-[218px] tw-h-[218px] tw-z-20" />
                <SvgoHomeZaIcon1 class="tw-absolute tw-top-16 tw-right-10 tw-w-[262px] tw-h-[262px] tw-z-20" />
                <SvgoHomeZaIcon2 class="tw-absolute tw-bottom-40 tw-right-4 tw-w-[218px] tw-h-[218px] tw-z-20" />
                <SvgoHomeZaIcon4 class="tw-absolute tw-bottom-36 -tw-left-28 tw-w-[312px] tw-h-[312px] tw-z-20" />
                <SvgoHomeZaIcon3 class="tw-absolute -tw-bottom-12 tw-left-36 tw-w-[260px] tw-h-[260px] tw-z-20" />
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<style scoped>
  .tw-home-za-bg {
    background-image: url('/res/img/home/South_Africa.png') !important;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
  .tw-home-za-icon1 {
    background-image: url('/res/img/home/sa.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
  .tw-home-za-icon2 {
    background-image: url('/res/img/home/sa-2.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
  .tw-home-za-icon3 {
    background-image: url('/res/img/home/sa-3.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
  .tw-home-za-icon4 {
    background-image: url('/res/img/home/sa-4.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
  .tw-home-za-icon5 {
    background-image: url('/res/img/home/sa-5.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
  .tw-home-za-icon6 {
    background-image: url('/res/img/home/sa-6.png') !important;
    background-position: center center;
    background-size: cover;
    position: absolute;
  }
</style>
