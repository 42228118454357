<template>
  <div class="tw-py-8 -tw-mx-8 tw-relative">
    <carousel v-bind="config" class="mask-edge" ref="carouselRef" v-model="currentSlide">
      <slide v-for="article in data" :key="article._id" class="tw-items-start tw-mr-6 last:tw-mr-0">
        <ArticleCard v-bind:key="article._link" v-bind:article="article" v-bind:index="article._id" @view-article="onViewArticle" />
      </slide>
    </carousel>

    <button
      class="tw-hidden lg:tw-flex tw-rounded-full tw-bg-primary tw-text-white tw-border-2 tw-border-primary tw-border-solid tw-transition-all tw-absolute tw-left-0 tw-top-[160px] hover:tw-text-black hover:tw-bg-white active:tw-text-primary"
      @click="prev"
    >
      <SvgoCommonArrowWhite class="tw-m-5 tw-w-5 tw-h-5 tw-rotate-180" />
    </button>
    <button
      class="tw-hidden lg:tw-flex tw-rounded-full tw-bg-primary tw-text-white tw-border-2 tw-border-primary tw-border-solid tw-transition-all tw-absolute tw-right-0 tw-top-[160px] hover:tw-text-black hover:tw-bg-white active:tw-text-primary"
      @click="next"
    >
      <SvgoCommonArrowWhite class="tw-m-5 tw-w-5 tw-h-5" />
    </button>

    <p class="tw-mt-8 tw-text-center tw-text-gray-300 tw-flex lg:tw-hidden tw-justify-center tw-items-center">
      <SvgoCommonArrowWhite class="tw-mx-2 tw-w-3 tw-h-3 tw-rotate-180" />
      SWIPE TO EXPLORE MORE
      <SvgoCommonArrowWhite class="tw-mx-2 tw-w-3 tw-h-3" />
    </p>
    <div class="tw-hidden lg:tw-flex dashed-line tw-mt-20"></div>
  </div>
</template>

<script lang="ts" setup>
  import ArticleCard from './ArticleCard.vue'
  import { usedKeys, getQueryParams } from './utils'

  const props = defineProps({
    limit: {
      type: Number,
      default: 5,
    },
  })

  const config = {
    itemsToShow: 1.5,
    snapAlign: 'center-even',
    wrapAround: true,
    breakpoints: {
      //640px and up
      640: {
        itemsToShow: 2,
        snapAlign: 'center-even',
      },
      960: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
      1280: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      1440: {
        itemsToShow: 3.5,
        snapAlign: 'start',
      },
    },
  }

  const carouselRef = ref()
  const currentSlide = ref(0)

  const next = () => carouselRef.value.next()
  const prev = () => carouselRef.value.prev()

  const page = ref(1)

  const skip = computed(() => (page.value - 1) * props.limit)

  const query = computed(() => getQueryParams(skip.value, props.limit))

  const { data } = await useAsyncData('repost-carousel-data', () =>
    queryContent(query.value).only(usedKeys).find(),
    {
      // lazy: true,
      // server: false
    })

  const onViewArticle = async (payload: any) => {
    await navigateTo({
      // name: '_tests-slug___en___default',
      name: 'newsroom-slug___en___default',
      params: { slug: payload.slug },
    })
  }

  // watchEffect(() => {
  //   console.log(data.value)
  // })
</script>

<style lang="scss" scoped>
  .mask-edge {
    --mask: linear-gradient(90deg, #fff6, #ffff 10%, #ffff 90%, #fff6 100%);

    -webkit-mask: var(--mask);
    mask: var(--mask);
  }
  .dashed-line {
    height: 2px;
    background: linear-gradient(to right, transparent 50%, #ffffff 50%),
      linear-gradient(
        to right,
        rgba(224, 224, 224, 0) 5%,
        rgba(224, 224, 224, 0.9) 25%,
        rgba(224, 224, 224, 0.9) 75%,
        rgba(224, 224, 224, 0) 95%
      );
    background-size: 18px 2px, 100% 2px;
  }
  .article-container {
    & :deep(p) {
      margin: 0 0 1rem;
    }
  }
</style>
